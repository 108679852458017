<template>
  <van-empty description="客服当前不在线，你可以拨打商家电话">
    <van-button icon="phone-o" round type="danger" class="bottom-button" @click="onCall">
      {{ store.tel }}
    </van-button>
  </van-empty>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import req from '../request-new'

export default {
  setup () {
    const Cookies = inject('Cookies')
    // const useRouter = inject('useRouter')
    const useRoute = inject('useRoute')
    const state = reactive({
      store: {},
      storeId: Cookies.get('storeId') ?? useRoute.query.storeId ?? ''
    })
    const m1 = () => {
      return req.post('/store.get', {
        storeId: state.storeId
      })
    }
    const m2 = () => {
      return req.post('/store.get', {
        storeId: state.storeId
      })
    }
    const init = () => {
      req.post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        console.log(res)
        state.store = res.data
      })
      req.all([m1(), m2()]).then(req.spread((m1, m2) => {
        console.log(1)
      }))
    }
    const onCall = () => {
      window.location.href = 'tel://' + state.store.tel
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onCall
    }
  }
}
</script>
